<template>
  <Head>
    <Style
      type="text/css"
      :children="`:root { ${bgColor} ${bgAltColor} ${textColor} ${navColor} ${buttonColor} ${textAltColor}}`"
    ></Style>
  </Head>

  <NuxtLoadingIndicator :color="bgColor" />
</template>

<script setup lang="ts">
const { store } = useDomainState()

const theme = {
  bg_color: '#f2f2f2',
  bg_alt_color: '#ffffff',
  text_color: '#130b0b', // used with bg, bg_alt colors
  nav_color: '#d52826',
  button_color: '#4e4e4e',
  text_alt_color: '#ffffff' // used with nav, button color
}

if (store.value?.use_custom_colors) {
  theme.bg_color = store.value.bg_color
  theme.bg_alt_color = store.value.bg_alt_color
  theme.text_color = store.value.text_color
  theme.nav_color = store.value.nav_color
  theme.button_color = store.value.button_color
  theme.text_alt_color = store.value.text_alt_color
}

const bgColor = useGetRGBColor(theme.bg_color, 'bg-color')
const bgAltColor = useGetRGBColor(theme.bg_alt_color, 'bg-alt-color')
const textColor = useGetRGBColor(theme.text_color, 'text-color')
const navColor = useGetRGBColor(theme.nav_color, 'nav-color')
const buttonColor = useGetRGBColor(theme.button_color, 'button-color')
const textAltColor = useGetRGBColor(theme.text_alt_color, 'text-alt-color')
</script>

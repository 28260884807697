<template>
  <v-menu v-if="locales?.length">
    <template #activator="{ props }">
      <div class="p-2 rounded" :class="classHover">
        <div
          v-bind="props"
          class="w-max flex items-center cursor-pointer rounded cursor-pointer"
        >
          <div v-if="enableTitle" class="mx-1 hidden md:block">
            {{ localeProperties.name }}
          </div>
          <v-icon :class="classIcon" icon="fa: fa-solid fa-globe" :size="24" />
        </div>
      </div>
    </template>
    <v-list>
      <div
        v-for="(localeTemp, index) in locales"
        :key="`locale-item ${index}`"
        class="my-1 mx-3 text-center cursor-pointer"
        @click="selectLocale(localeTemp.code)"
      >
        <ClientOnly>
          <div :class="localeTemp.code == locale ? classActiveLang:''">
            {{ localeTemp.name }}
          </div>
        </ClientOnly>
      </div>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
const localeStore = useLocaleStore()
const { localeProperties, locale } = useI18n()
const locales = useLocales()
withDefaults(
  defineProps<{
    enableTitle?: boolean,
    classHover?: string,
    classIcon?: string,
    classActiveLang?: string
  }>(),
  {
    classHover: 'hover:bg-nav_color hover:bg-opacity-10',
    classActiveLang: 'bg-bg_color',
    classIcon: '',
    enableTitle: false
  }
)
function selectLocale(localeCode: any) {
  /* localeStore.localeLan  */
  if (locale !== localeCode.toString()) {
    localeStore.setLocaleWithRestart(localeCode)
  }
}
</script>

import type { LocaleObject } from '@nuxtjs/i18n/dist/runtime/composables'
import { defineStore } from 'pinia'
import { reloadNuxtApp } from 'nuxt/app'

export const useLocaleStore = defineStore('Locale', {
  state: (): {
    localeLan: string
  } => ({
    localeLan: 'ar'
  }),

  actions: {
    setLocale(locale: any) {
      const switchLocalePath = useSwitchLocalePath()
      const router = useRouter()

      this.localeLan = locale
      router.replace(switchLocalePath(locale))
    },

    setLocaleWithRestart(locale: any) {
      const switchLocalePath = useSwitchLocalePath()
      this.localeLan = locale
      reloadNuxtApp({
        path: switchLocalePath(locale),
        ttl: 1
      })
    }
  }

  // persist: [{ pick: ['localeLan'], storage: persistedState.cookies }]
})

export const useLocales = (): LocaleObject[] => {
  const { layout } = useDomainHost()
  const { store } = useDomainState()
  const { locales } = useI18n()

  if (layout === 'domain') {
    if (store.value?.allowed_languages?.length) {
      return (locales.value as LocaleObject[])?.filter((el) => {
        return store.value?.allowed_languages.includes(el.code)
      })
    }

    return []
  }

  return locales.value as LocaleObject[]
}

<template>
  <div class="bg-bg_color text-text_color">
    <div v-if="store" class="p-4">
      <div class="cursor-pointer mb-5" @click="goToRoute('/')">
        <NuxtImg
          provider="cacheIpx"
          preset="modified"
          loading="lazy"
          :src="store.icon"
          width="48"
          height="48"
          class="w-24 h-24 mx-auto rounded"
          :alt="store.name"
        />
      </div>

      <div class="border-b-2 bg-bg_color"></div>

      <div
        v-for="(item, index) in menu"
        :key="`drawer-menu-item-${index}`"
        class="item-drawer"
        :class="{
          'text-text_alt_color':
            item.route.query.category_id.toString() == route.query.category_id
        }"
        @click="goToRoute(item.route)"
      >
        <span class="font-medium">
          {{ item.title }}
        </span>
        <div class="item-text-drawer">
          <div class="rounded-full p-1 border">
            <NuxtImg
              v-if="item.image"
              provider="cacheIpx"
              preset="modified"
              loading="lazy"
              width="48"
              height="48"
              class="p-0.5"
              :src="item.image"
              :alt="item.title"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDomainCategoriesStore } from '~/store/domain_categories'

const { store } = useDomainState()
const { categoriesByCategoryStyle } = useDomainCategoriesStore()
const route = useRoute()
const router = useRouter()

const emit = defineEmits(['close'])

const menu = computed(() => {
  return categoriesByCategoryStyle.slice(0, 10).map((el) => ({
    title: el.name,
    image: el.image,
    route: {
      path: '/materials',
      query: { category_id: el.id, branch_id: useBranchId() }
    }
  }))
})

function goToRoute(route: any) {
  emit('close')
  const localePath = useLocalePath()
  router.push(localePath(route))
}
</script>

<style scoped>
.item-drawer {
  @apply py-2 px-14 my-3 rounded-md relative transition-all duration-300 hover:bg-nav_color hover:text-text_alt_color hover:bg-opacity-80 cursor-pointer;
}
.item-drawer.active {
  @apply bg-nav_color text-text_alt_color bg-opacity-80;
}
.item-text-drawer {
  @apply bg-nav_color w-12 h-12 rounded-full border-4 border-bg_color absolute -right-2.5 inset-y-1/2 transform -translate-y-1/2;
}
</style>

<template>
  <div></div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import customToast from './CustomToastPwa.vue'

const toast = useToast()

if (process.client) {
  const { $pwa } = useNuxtApp()
  const { t } = useI18n()
  $pwa.showInstallPrompt = !$pwa.isInstalled
  if ($pwa?.needRefresh) {
    toast({
      component: customToast,
      props: {
        title: t('upgrade', { name: t('app') }),
        titleTap: t('upgrade'),
        titleExit: t('cancel')
      },
      listeners: {
        onTap: $pwa.updateServiceWorker,
        onExit: $pwa.cancelPrompt
      }
    })
  }
  if ($pwa?.showInstallPrompt && !$pwa?.offlineReady && !$pwa?.needRefresh) {
    toast({
      component: customToast,
      props: {
        title: t('install', { name: t('app') }),
        titleTap: t('install'),
        titleExit: t('cancel')
      },
      listeners: {
        onTap: $pwa.install,
        onExit: $pwa.cancelPrompt
      }
    })
  }
}
</script>

<style>
.Vue-Toastification__toast--default {
  background-color: #fff ;
  color: #000 !important;
}

.Vue-Toastification__container .Vue-Toastification__toast {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  border-radius: 8px !important;
}

.Vue-Toastification__close-button {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;

  padding-left: 10px;
  cursor: pointer;
  transition: 0.3s ease;
  align-items: center;
  color: #000000b4;
  opacity: 0.3;
  transition: visibility 0s, opacity 0.2s linear;
}
</style>

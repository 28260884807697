<template>
  <div class="flex items-center text-sm">
    <div class="w-0 flex-1">{{ title }}</div>
    <div class="flex items-center gap-2 mx-2">
      <div class="bg-gray-100 py-1 px-2  border rounded-lg " @click="emit('onTap')">
        {{ titleTap }}
      </div>
      <div class=" p-1" @click="emit('onExit')">
        {{ titleExit }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const emit = defineEmits(['onTap', 'onExit'])
defineProps<{
 title: string,
  titleTap: string,
  titleExit: string,
}>()
</script>

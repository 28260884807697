<template>
  <div>
    <ThemeHeadStore />

    <Head v-if="store?.icon">
      <Link rel="icon" type="image/x-icon" :href="store.icon" />
    </Head>

    <PixelScript v-if="store?.facebook_info" :id="store.facebook_info" />

    <PwaPrompt />

    <slot />
  </div>
</template>

<script setup lang="ts">
import { useDomainCategoriesStore } from '~/store/domain_categories'
import type { Store } from '~/composables/useMenuModel'
import getAllRoutes from '~/composables/AppApiRoutes'
const { storeModel } = useMenuModel()
const { getUuid } = useCartStore()
const { host } = useDomainHost()
const { store, setStore } = useDomainState()
const locale = useNuxtApp().$i18n?.locale?.value

const { data: domainStore, error } = await useFetch(
  '/nuxt-api/laravel/store',
  {
    query: {
      app_locale: locale,
      id: host,
      domain: useDomainHeader()
    },

    transform: (data: SuccessResponse<Store>) => data.data
  }
)
if (error.value) {
  createError(error.value)
}

setStore(domainStore.value)
if (domainStore.value) {
  const { data: dataTemp } = await useBasicFetch(
    getAllRoutes().storesRoutes.doseUserHaveTable(domainStore.value.id),
    {
      method: 'POST',
      body: {
        app_locale: locale,
        client_uuid: getUuid() || undefined
      }
    }
  )
  domainStore.value.is_user_have_table = dataTemp.value?.data
    ?.dose_user_have_table as any
  domainStore.value = storeModel(domainStore.value)
  await useDomainCategoriesStore().fetchCategories(domainStore.value)
  await useMaterialDefinitionsStore().fetchMaterialDefinitionsStore(
    domainStore.value
  )
  await useStoresSettings().fetchSettingsStore(domainStore.value)
}

</script>
